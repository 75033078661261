
export const getHomeGreeting = (firstName: string) => {
  if (!!firstName) {
    return `${getGreeting()}, ${firstName} ${getEmoji()}`;
  } else {
    return `${getGreeting()} ${getEmoji()}`;
  }
}

const getGreeting = (): string => {
  return "Hey";
}

const STANDARD_EMOJIS = ["😃", "👋", "🛍", "🛒", "🏪", "🍌", "🍎", "🍓", "🍞", "🧀", "🧅", "🧄", "🍅", "🍏", "🍇", "🍫", "🍬", "🍩", "🍪", "🍿"];
const getEmoji = (): string => {
  return STANDARD_EMOJIS[Math.floor(Math.random()*STANDARD_EMOJIS.length)];
}