import React from 'react';
import dynamic from 'next/dynamic';
import { DashboardContentProps } from 'components/Dashboard/DashboardContent'

const DashboardStatistics = dynamic(() => import('./components/DashboardStatistics'));
const RemoteReportDateTimeBreakdown = dynamic(() => import('components/RemoteReports/DateTimeBreakdown/RemoteReportDateTimeBreakdown'));

const DashboardSalesTab: React.FC<DashboardContentProps> = props => {

  const { report } = props;

  let totalTransactions = report?.overall.countTransactions || 0;

  if (totalTransactions < 1) return <DashboardStatistics {...props} />;

  return (
    <div>
      <DashboardStatistics report={report} />
      <RemoteReportDateTimeBreakdown data={report?.breakdowns} />
    </div>
  );
};

export default DashboardSalesTab;