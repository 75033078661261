import { RemoteReportsProvider } from 'contexts/remote-reports';
import React, { useEffect, useMemo } from 'react';
import protectedRoute from 'helper/protectedRoute';
import Dashboard from 'containers/Dashboard/Dashboard';
import useShopContext from 'contexts/shop/shop.context';
import useAuth from 'contexts/auth/auth.context';
import { DeviceType } from 'helper/useDeviceType';
import { SEO } from 'components/SEO/SEO';
import Mixpanel from 'helper/Mixpanel';
import { FullScreenLoader } from 'containers/AppLayout/AppLayout.style';
import { useProtectWithPermission } from 'endpoints/permissions/useProtectWithPermission';
import { LOGIN_ROUTE } from 'constants/navigation';
import usePermissions from 'endpoints/permissions/usePermissions';
import { getTheFirstPermittedPageRoute } from 'models/Permissions';

type HomeProps = {
  deviceType: DeviceType;
};

const Home: React.FC<HomeProps> = ({ deviceType }) => {
  const { permissions, fetchingGroups } = usePermissions();
  const redirectRoute = useMemo(
    () => getTheFirstPermittedPageRoute(permissions),
    [permissions]
  );
  useProtectWithPermission('partner_centre_home', redirectRoute, {
    skip: fetchingGroups,
  });
  const { authState } = useAuth();
  const { shopState } = useShopContext();
  const { user } = authState;

  useEffect(() => Mixpanel.track('dashboard'), []);

  if (!user || !shopState) {
    return (
      <>
        <SEO title={`Devo Partner Centre`} />
        <FullScreenLoader />
      </>
    );
  }

  return (
    <>
      <SEO title={`${shopState.name} | Devo Partner Centre`} />
      <RemoteReportsProvider id={1}>
        <Dashboard />
      </RemoteReportsProvider>
    </>
  );
};

export default protectedRoute(Home);
