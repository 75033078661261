import styled from 'styled-components';
import { StyleBreakpoints } from 'styles/Global'

export const Wrapper = styled.div`
  padding: 50px;
  margin: 0 auto;
  
  .time-frame-picker, .devo-daterange-picker {
    margin-bottom: 20px;  
  }

  @media (max-width: ${StyleBreakpoints.MEDIUM}px) {
    padding: 24px 8px;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
`;

export const Column = styled.div`
  &:only-child {
    width: 90%;
    max-width: 850px;
    margin: 0 auto;
  }
  &:not(:only-child) {
    width: calc(50% - 10px);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media (max-width: ${StyleBreakpoints.MEDIUM}px) {
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 16px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 6px;
  margin-left: .5rem;
  
  h1 {
    font-size: 32px;
    font-weight: 800;
  }

  span.caption {
    display: block;
    font-size: 16px;
    color: var(--secondary-label-color);
    margin: 0;
  }

  @media (max-width: ${StyleBreakpoints.MEDIUM}px) {
    margin: 0 0 20px 0;
  }
`;

type StatisticsContainerProps = { sections: number };
export const StatisticsContainer = styled.div<StatisticsContainerProps>`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  flex-grow: ${props => props.sections};
  flex-shrink: ${props => props.sections};
  margin: 20px 0;

  & > div {
    width: calc(100% / ${props => props.sections || 4});
  }

  @media (max-width: ${StyleBreakpoints.MEDIUM}px) {
    margin: 0;
    flex-flow: column nowrap;

    & > div {
      margin: 20px 0;
      width: 100%;
    }
  }
`;

export const Statistic = styled.div`
  text-align: center;

  span {
    font-size: 32px;
    font-weight: 600;

    .small {
      font-size: 20px;
    }
  }

  &.green span { color: var(--devo-green); }
  &.red span { color: var(--devo-red); }
  &.blue span { color: var(--devo-blue); }
  

  p {
    color: var(--secondary-label-color);
    margin: 0;
  }
`;

type ModuleContainerProps = { adjustForTitle?: boolean; }
export const ModuleContainer = styled.div<ModuleContainerProps>`
  width: 100%;
  padding: 36px 24px;
  background-color: var(--background-color);
  border-radius: var(--devo-border-radius);
  box-shadow: var(--devo-shadow);
  overflow: hidden;
  
  &:not(.nospace) { margin-bottom: 20px; }

  ${props => props.adjustForTitle ? `
    padding-top: 24px;
    & > h3 { margin: 4px 0 24px 8px; }
  ` : ""}
  
  & > .header {
    margin: 15px 20px 0;
    p { color: var(--secondary-label-color); }
  }

  & > .graph {
    height: calc(100% - 100px);

    .recharts-tooltip-label {
      font-weight: 600;
      font-size: 20px;
    }
  }

  & > .table-responsive {
    padding: 0 10px;
  }

  .product {
    margin: 0 -20px;
  }

  @media (max-width: ${StyleBreakpoints.MEDIUM}px) {
    padding: 20px 10px;

    & > .graph {
      height: calc(100% - 150px);
    }
  }
`;

export const ModuleHeader = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin: 15px 20px 0
    }
    p, a.report {
      display: inline-block;
      margin: 10px 20px 30px;
      color: var(--secondary-label-color);
    }
    a.report { 
      cursor: pointer;
      color: var(--devo-blue); 
    }
    a.report:hover { 
      color: var(--devo-dark-blue); 
      text-decoration: underline; 
    }
  `,
  Left: styled.div`
    &.clamp {
      min-width: 0;
      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  `,
  Right: styled.div`
  
  `,
}

export const ModuleContent = styled.div`
  padding: 0 20px;

  & > p {
    color: var(--secondary-label-color);
    margin: 0;
  }
`;

export const ExpandModule = styled.div`
  margin: 10px 0;
  color: var(--blue);
  text-align: center;

  * {
    cursor: pointer;
    display: inline-block;
    margin: 0 auto;
  }
`;

export const Postcode = {
  Row: styled.div`
    margin-bottom: 20px;
  `,
}