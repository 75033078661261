import React, { useEffect, useState } from 'react'
import useRemoteReportsContext from 'contexts/remote-reports'
import DashboardCategoriesTab from 'components/Dashboard/tabs/DashboardCategoriesTab'
import DashboardProductsTab from 'components/Dashboard/tabs/DashboardProductsTab'
import CategoryRemoteReportModel from 'models/RemoteReports/CategoryRemoteReports'
import DashboardTransactionsTab from 'components/Dashboard/tabs/DashboardTransactionsTab'
import DashboardSalesTab from 'components/Dashboard/tabs/DashboardSalesTab'
import { Tab, Tabs } from 'react-bootstrap'
import { TabContainer } from 'styles/Global'

type TabType = "TRANSACTIONS" | "SALES" | "CATEGORIES" | "TRENDS";

export type DashboardContentProps = {
  report: CategoryRemoteReportModel;
}

const DashboardContent: React.FC<DashboardContentProps> = props => {

  const { setViewMode } = useRemoteReportsContext();
  const [tab, setTab] = useState<TabType>("TRANSACTIONS");

  useEffect(() => {
    switch (tab) {
      case "TRANSACTIONS":
        setViewMode("TRANSACTIONS_AND_REVENUE");
        return;
      case "SALES":
        setViewMode("SALES_AND_REVENUE");
        return;
      case "CATEGORIES":
        setViewMode("SALES_AND_REVENUE");
        return;
      case "TRENDS":
        setViewMode("SALES_AND_REVENUE");
        return;
    }
  }, [tab]);

  let sales = props.report?.overall.countProducts || 0;
  let bestSellersCategoryIds = Object.keys(props.report?.categoryBreakdown || {});
  let bestSellersUpcs = Object.keys(props.report?.topSellersBreakdown || {});

  if (sales == 0) return <TabContainer>
    <DashboardTransactionsTab { ...props } />
  </TabContainer>

  return (
    <TabContainer>
      <Tabs activeKey={tab} variant={"pills"} onSelect={tab => setTab(tab as TabType)} className={"hidescrollbar"}>

        <Tab eventKey={"TRANSACTIONS"} title={"Transactions"}>
          <DashboardTransactionsTab { ...props } />
        </Tab>

        <Tab eventKey={'SALES'} title={'Sales'}>
          <DashboardSalesTab {...props} />
        </Tab>

        {bestSellersCategoryIds.length > 0 &&
          <Tab eventKey={'CATEGORIES'} title={'Categories'}>
            <DashboardCategoriesTab {...props} />
          </Tab>
        }

        {bestSellersUpcs.length > 0 &&
          <Tab eventKey={'PRODUCTS'} title={'Best Sellers'}>
            <DashboardProductsTab {...props} />
          </Tab>
        }
      </Tabs>
    </TabContainer>
  )
};

export default DashboardContent;