import React from 'react';
import useRemoteReportsContext from 'contexts/remote-reports'
import { getTimeFrameDescription } from 'helper/datetime/timeFrame'
import dynamic from 'next/dynamic';
import { DashboardContentProps } from 'components/Dashboard/DashboardContent'
import { Caption, Module, ModuleHeader } from 'styles/Global'

const RemoteReportProductList = dynamic(() => import('components/RemoteReports/ProductCategoryBreakdown/RemoteReportProductList'));

const DashboardProductsTab: React.FC<DashboardContentProps> = props => {

  const { timeFrame: timeFrameProps } = useRemoteReportsContext();
  const { timeFrame } = timeFrameProps;

  return (
    <Module>
      <ModuleHeader>
        <div>
          <h3>Top Sellers</h3>
          <Caption>Here are your shop's top performing sellers {getTimeFrameDescription(timeFrame)}.</Caption>
        </div>
      </ModuleHeader>

      <RemoteReportProductList data={props.report.topSellersBreakdown} />
    </Module>
  );
};

export default DashboardProductsTab;