import { useQuery } from '@apollo/client'
import useShopContext from 'contexts/shop/shop.context'
import gql from 'graphql-tag'
import {formatYYYYMMDD, useFromToDateStrings} from 'helper/datetime/useFromToDateStrings'
import CategoryRemoteReportModel from 'models/RemoteReports/CategoryRemoteReports'
import {addDaysToDate} from "../../helper/datetime/dates";

interface UseCategoryRemoteReportProps {
  categoryId: number;
  fromDate: Date;
  toDate: Date;
}

interface UseCategoryRemoteReportCallback {
  report: CategoryRemoteReportModel;
  loading: boolean;
  error: any;
}

let QUERY_PARAMS = "shopUuid={args.shopUuid}&categoryId={args.categoryId}&fromDate={args.fromDate}&toDate={args.toDate}";
const QUERY = gql`
  query getCategoryRemoteReport($shopUuid: string, $categoryId: number, $fromDate: number, $toDate: number) {
    report(shopUuid: $shopUuid, categoryId: $categoryId, fromDate: $fromDate, toDate: $toDate)
    @rest(type: "CategoryRemoteReportModel", method: "GET", path: "/pos/revenueSummaryReport?${QUERY_PARAMS}", endpoint: "ss") {
      shopUuid
      date
      from
      to
      categoryId
      rangeType
      overall
      breakdowns
      topSellersBreakdown
      onlineTopSellersBreakdown
      categoryBreakdown
      onlineCategoryBreakdown
    }
  }
`;

const useCategoryRemoteReport = (props: UseCategoryRemoteReportProps): UseCategoryRemoteReportCallback => {

  const { categoryId, fromDate, toDate } = props;
  const { shopState } = useShopContext();
  let shopUuid = shopState?.uuid;

  const { fromDateStr, toDateStr } = useFromToDateStrings(fromDate, toDate);

  const { data, loading, error } = useQuery(QUERY, {
    skip: !shopUuid || !fromDateStr || !toDateStr,
    variables: {
      shopUuid,
      categoryId,
      fromDate: fromDateStr,
      toDate: formatYYYYMMDD(addDaysToDate(new Date(toDateStr + 'T00:00:00Z'), 1 + (toDate.getTimezoneOffset() > 0 ? 1 : 0))),
    },
  });

  let report: CategoryRemoteReportModel = data?.report;

  return {
    report,
    loading,
    error
  }
}

export default useCategoryRemoteReport;
