import React from 'react';
import dynamic from 'next/dynamic';
import { DashboardContentProps } from 'components/Dashboard/DashboardContent'

const DashboardCategoryList = dynamic(() => import('./components/DashboardCategoryList'));

const DashboardCategoriesTab: React.FC<DashboardContentProps> = (props) => {
  return (
    <div>
      <DashboardCategoryList {...props} />
    </div>
  );
};

export default DashboardCategoriesTab;